import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import InlineInfoCard from "../../components/template-partials/global-components/inline-infocard"
import VideoInfoCard from "../../components/template-partials/global-components/video-infocard"
import QuestionInfocard from "../../components/template-partials/global-components/question-infocard"
import InTreatmentSubmenu from "../../components/template-partials/in-treatment/in-treatment-submenu"

import { ExploreCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/3.0-headerbg.jpg"
import headingRound from "../../images/in-treatment-nav-image.jpg"
import observation from "../../images/observation-bug.jpg"
import chemotherapy from "../../images/chemotherapy-bug.jpg"
import surgery from "../../images/surgery-bug.jpg"
import tumorShrink from "../../images/3.1_TUMORshrink.jpg"
import noofCycles from "../../images/3.1_noofcycles.jpg"
import threeWeek from "../../images/3.1_3week.jpg"
import evaluateResponse from "../../images/3.1_evaluateresponse.jpg"
import videoScreen from "../../images/tv-lblue.png"

import chemo from "../../images/3.1-chemo.jpg"
import surgeryc from "../../images/3.1-surgery.jpg"

import VideoPlayer from "../../components/VideoPlayer"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"

const NonHighRiskPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: "unslick" },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Layout>
      <SEO
        title="Non–High-Risk Neuroblastoma Treatment"
        description="An overview of the treatment journey for children with low-risk and intermediate-risk neuroblastoma, including information about surgery and chemotherapy."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Non—High-Risk (Low- or Intermediate-Risk) Treatment"
      >
        <h1 className="section__heading section__heading--green h1">
          Non—High-Risk (Low- <em>or</em> Intermediate-Risk) Treatment
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`In Treatment`}>
              <InTreatmentSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">
                  Treatment for non–high-risk (low- or intermediate-risk)
                  neuroblastoma
                </h2>
                <p>
                  Children with{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#non-high-risk-neuroblastoma"
                  >
                    non–high-risk neuroblastoma
                  </Link>{" "}
                  have a{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#low-risk-neuroblastoma"
                  >
                    low
                  </Link>
                  - or{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#intermediate-risk-neuroblastoma"
                  >
                    intermediate-risk
                  </Link>{" "}
                  classification. They make up about half of the newly diagnosed
                  cases each year. This group has excellent{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#event-free-survival-or-efs"
                  >
                    event-free
                  </Link>{" "}
                  and{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#overall-survival-or-os"
                  >
                    overall survival
                  </Link>{" "}
                  with current therapy in{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#clinical-trials"
                  >
                    clinical trials
                  </Link>
                  . Children with non–high-risk neuroblastoma generally have
                  better treatment outcomes than those with{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#high-risk-neuroblastoma"
                  >
                    high-risk neuroblastoma
                  </Link>
                  .{" "}
                  <Link to="/diagnosis/understanding-treatment/">
                    Learn about the differences between non–high-risk and
                    high-risk neuroblastoma classifications.
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-3">
          <div className="columns">
            <p>
              <strong>
                Recently, the goal for treatment of non–high-risk neuroblastoma
                has been to reduce the intensity of therapy to minimize short-
                and{" "}
                <Link
                  rel="glossary"
                  to="/resources/glossary/#long-term-side-effect"
                >
                  long-term side effects
                </Link>{" "}
                while continuing to maintain excellent outcomes.
              </strong>
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Therapies depend on risk classification
            </h3>
            <p>
              The therapies used for your child’s{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#non-high-risk-neuroblastoma"
              >
                non–high-risk neuroblastoma
              </Link>{" "}
              will depend on whether their neuroblastoma is{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#low-risk-neuroblastoma"
              >
                low
              </Link>{" "}
              or{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#intermediate-risk-neuroblastoma"
              >
                intermediate risk
              </Link>
              .
            </p>
          </div>
        </div>
        <div className="row row--inner align-center lg_mt-2">
          <div className="columns small-11 large-4">
            <div className="card" style={{ borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad">
                    <strong className="tc-midGrey">Observation</strong>
                  </p>
                </div>
                <div className="columns small-4">
                  <img
                    src={observation}
                    width="100%"
                    height="auto"
                    alt="stage of disease"
                  />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">Low risk only </p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card" style={{ borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad">
                    <strong className="tc-midGrey">Surgery</strong>
                  </p>
                </div>
                <div className="columns small-4">
                  <img src={surgery} width="100%" height="auto" alt="age" />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">
                    Low risk <br /> Intermediate risk
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card" style={{ borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad">
                    <strong className="tc-midGrey">Chemotherapy</strong>
                  </p>
                </div>
                <div className="columns small-4">
                  <img
                    src={chemotherapy}
                    width="100%"
                    height="auto"
                    alt="Tumor characteristics"
                  />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">Intermediate risk only</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 mt-2">
          <div className="columns">
            <p>
              As your child’s treatment for non–high-risk neuroblastoma begins,
              the healthcare team will go over how and why each therapy is used.
              Below is an overview of treatment to help you and your family
              prepare.
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Observation </h3>
            <p>
              Observation is sometimes referred to as{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#watch-and-wait"
              >
                watch and wait
              </Link>
              . The healthcare team monitors your child to see if the tumors
              disappear or start to grow.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              For low-risk neuroblastoma{" "}
            </h4>
            <p>
              Observation is only used for{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#low-risk-neuroblastoma"
              >
                low-risk neuroblastoma
              </Link>
              . In some cases with young infants who have low-risk
              neuroblastoma, the tumors will mature and go away on their own,
              and no treatment is necessary.
            </p>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.honeydew }}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Surgery </h3>
            <p>
              Surgery is used to remove as much of your child’s tumor as
              possible. Removal of the tumor is also known as{" "}
              <Link rel="glossary" to="/resources/glossary/#resection">
                resection
              </Link>
              .
            </p>
            <ul className="ul--dotted">
              <li>
                In some cases, a complete removal of the tumor is possible and
                the surgery may be the only treatment your child requires
              </li>
              <li>
                Only a partial removal of the tumor is possible if your child’s
                tumor is near vital structures or wrapped around large blood
                vessels
              </li>
            </ul>
          </div>
          <div className="columns small-12 large-4 text-center large-text-right">
            <FancyImage
              url={surgeryc}
              alt="Surgery is used to remove as much of your child’s tumor as possible."
            />
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 mt-2">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              For low-risk neuroblastoma{" "}
            </h4>
            <p>
              Surgery is often the only treatment needed. If the entire tumor is
              not able to be removed, oncologists will monitor it closely and
              then determine whether additional therapy is necessary.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 mt-2">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              For intermediate-risk neuroblastoma{" "}
            </h4>
            <p>
              Surgery will be used after{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#chemotherapy"
              >
                chemotherapy
              </Link>{" "}
              to remove as much of the tumor as possible.{" "}
            </p>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner align-middle">
          <div className="columns small-12 large-4 text-center large-text-right">
            <FancyImage
              url={chemo}
              alt="Chemotherapy for children with neuroblastoma can be used before surgery (neoadjuvant chemotherapy) or after surgery (adjuvant chemotherapy)."
            />
          </div>
          <div className="columns mt-2">
            <h3 className="h2 tc-barney jost-med">Chemotherapy </h3>
            <p>
              <Link
                rel="glossary"
                to="/resources/glossary/#chemotherapy"
              >
                Chemotherapy
              </Link>{" "}
              for children with neuroblastoma can be used before surgery (
              <Link
                rel="glossary"
                to="/resources/glossary/#neoadjuvant-chemotherapy"
              >
                neoadjuvant chemotherapy
              </Link>
              ) or after surgery (
              <Link
                rel="glossary"
                to="/resources/glossary/#adjuvant-chemotherapy"
              >
                adjuvant chemotherapy
              </Link>
              ). Chemotherapy medicines will stop cancer cells from growing,
              either by killing the cells or by stopping them from dividing and
              reproducing.
            </p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-3">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              For intermediate-risk neuroblastoma{" "}
            </h4>
            <p>
              Children may have tumors that have unfavorable characteristics or
              are not easy to remove with surgery alone. In this case, your
              child’s healthcare team will start chemotherapy to treat the
              neuroblastoma. Generally, you can expect the following with
              chemotherapy.{" "}
            </p>
          </div>
        </div>
        <div className="row row--inner lg_mt-3">
          <div className="columns">
            <Slider className="grid-slider" {...settings}>
              <div className="text-center">
                <img
                  src={tumorShrink}
                  alt="Chemotherapy is administered to help shrink your child’s tumor for easier removal"
                  style={{ maxWidth: `129px` }}
                />
                <p>
                  Given first to help shrink the tumor and make it easier for
                  the surgeon to remove
                </p>
              </div>
              <div className="text-center">
                <img
                  src={noofCycles}
                  alt="A combination of multiple chemotherapy medicines may be used to treat your child’s neuroblastoma"
                  style={{ maxWidth: `119px` }}
                />
                <p>Uses multiple chemotherapy medicines in combination </p>
              </div>
              <div className="text-center">
                <img
                  src={threeWeek}
                  alt="Chemotherapy is administered in cycles every 3 weeks"
                  style={{ maxWidth: `129px` }}
                />
                <p>
                  Given in{" "}
                  <Link rel="glossary" to="/resources/glossary/#cycle">
                    cycles
                  </Link>{" "}
                  every 3 weeks
                </p>
              </div>
              <div className="text-center">
                <img
                  src={evaluateResponse}
                  alt="Every few cycles your child’s oncologist will check how the tumor is responding to chemotherapy"
                  style={{ maxWidth: `109px` }}
                />
                <p>
                  Evaluated every few cycles by the oncologist to see how the
                  tumor is responding
                </p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="row row--inner lg_mt-3">
          <div className="columns">
            <p>
              <strong>
                The number of chemotherapy cycles your child needs depends on
                how much the tumor shrinks (responds to chemotherapy).
              </strong>
            </p>
            <p>
              Most often your child will be given more than one chemotherapy
              medicine. This is known as{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#combination-chemotherapy"
              >
                combination chemotherapy
              </Link>
              . It is often given in a clinic or an{" "}
              <Link rel="glossary" to="/resources/glossary/#outpatient">
                outpatient
              </Link>{" "}
              setting (not in the hospital).{" "}
            </p>
            <p>
              The most common chemotherapy drugs used in{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#non-high-risk-neuroblastoma"
              >
                non–high-risk neuroblastoma
              </Link>{" "}
              treatment are:
            </p>
            <div className="row">
              <div className="columns lg_mt-1 mt-1">
                <ul className="ul--dotted">
                  <li>Carboplatin</li>
                  <li>Etoposide</li>
                  <li>Cyclophosphamide</li>
                  <li>Doxorubicin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <InlineInfoCard
          className="lg_mt-2 mt-2"
          text="It is important to ask your child’s healthcare team about the chemotherapy regimen that will be specifically tailored for your child. Side effects from chemotherapy differ from medicine to medicine. Knowing which medicines will be used can help you understand what treatment side effects to expect."
        />

        <div className="row row--inner lg_mt-2 mt-2">
          <div className="columns">
            <p>
              Many of the physical changes or side effects due to chemotherapy
              are not permanent. It is okay to rely on your child’s healthcare
              team to figure out how to help manage the side effects, and to
              talk to your child about some of the changes.{" "}
              <Link to="/supportive-care/">
                Learn more about how to help manage side effects and about
                supportive care for your child during chemotherapy
              </Link>
              .
            </p>
          </div>
        </div>


        <QuestionInfocard
          className="lg_mt-4 mt-4"
          heading="What side effects could my child have, based on their specific treatment plan?"
          text="Find more helpful questions to ask your doctor."
          link="/resources/doctor-discussion-guide/"
        />
      </Section>

      <InteriorPageFooter>
        <ExploreCard
          single="true"
          title="How can I help my child when they experience side effects?"
          lead="Learn how to help manage side effects related to treatment in the Supportive Care section."
          url="/supportive-care/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default NonHighRiskPage
